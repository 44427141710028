import * as am4core from "@amcharts/amcharts4/core";

export const addChartLine = (line, valueY, stroke) => {
  line.dataFields.dateX = "date";
  line.dataFields.valueY = valueY;
  line.tooltipText = "{valueY}";
  line.stroke = stroke;
  line.tooltip.getFillFromObject = false;
  line.tooltip.background.fill = am4core.color(stroke);
};

export default { addChartLine };
